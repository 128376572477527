import { captureException } from '@sentry/nextjs';
import qs from 'qs';
import humps, { Camelized, Decamelized } from 'humps';
import {
  ChatMessage,
  User,
  Activity,
  ActivityPage,
  Blog,
  Category,
  City,
  CityPage,
  Collection,
  CollectionPage,
  CorporateInquiry,
  Country,
  CountryPage,
  FaqItem,
  GoogleReview,
  GoogleReviewInfo,
  InitialData,
  Option,
  OptionPage,
  Pagination,
  PromotionCode,
  Review,
  Supplier,
  Tag,
  Waitlist,
  GiftCard,
  Image,
  Cart,
  ApiError,
  GiftCardRedemption,
  Order,
  SearchResult,
  AvailableDate,
  DetailedTimeslot,
  CorporateEvent,
  HomePage,
  Currency,
  CheckAvailableTimeslot,
  PromotionCodeRedemption,
  Payment,
  CheckoutPay,
  GiftCode,
  SiteReview,
  OrderItemCustomerTrip,
  Price,
  ChatConversation,
  OrderItemChangeRequest,
  PaymentCard,
  TravelItinerary,
} from 'src/types';
import { AdminRoutes, ApiRoutes } from './routes';
import { omit } from 'lodash-es';
import corsFetch from './cors_fetch';
import { RECAPTCHA_KEY } from 'src/lib/constants';
import { getAccessToken } from 'src/lib/access_token';

type ApiListResponse<T, K = {}> = { data: T[]; pagination: Pagination; extra?: K };
type ApiResponse<T> = T | ApiError;
export type BasicHttpHeader = Record<string, string | undefined>;
type RansackParams = { q?: { [key: string]: any } };
type ViewParams = { view?: string };
type PaginationParams = {
  page?: number;
  per_page?: number;
};

type HTTP_METHOD = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

const headersWithUserToken: (headers?: BasicHttpHeader) => BasicHttpHeader & { 'X-User-Token'?: string } = (headers) => {
  const userAccessToken = getAccessToken()
  return {
    ...(userAccessToken ? { 'X-User-Token': userAccessToken } : {}),
    ...(headers || {})
  }
}
class ApiClient {
  static async getInitialData(
    params?: PaginationParams & { q?: Record<string, any> },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<InitialData>(
      'GET',
      ApiRoutes.apiInitialDataRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getImages(
    params?: PaginationParams & { q?: Record<string, any> },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<Image>>(
      'GET',
      ApiRoutes.apiImagesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getGiftCards(
    params?: PaginationParams & { q?: Record<string, any> },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<GiftCard>>(
      'GET',
      ApiRoutes.apiGiftCardsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getPromotionCode(params: { id: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<PromotionCode>>(
      'POST',
      ApiRoutes.apiPromotionCodesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getPromotionCodes(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<PromotionCode>>(
      'GET',
      ApiRoutes.apiPromotionCodesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async redeemGiftExperience(params: { code: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<PromotionCode>>(
      'POST',
      ApiRoutes.apiRedeemGiftExperiencesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getCountries(params?: PaginationParams & RansackParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Country>>(
      'GET',
      ApiRoutes.apiCountriesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getCities(params?: PaginationParams & RansackParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<City>>(
      'GET',
      ApiRoutes.apiCitiesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getActivities(
    params?: PaginationParams & { q?: Record<string, any> },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<Activity>>(
      'GET',
      ApiRoutes.apiActivitiesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getActivity(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Activity>>(
      'GET',
      ApiRoutes.apiActivityRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getAvailableTimeslot(
    params: { order_item_id: string | number },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<CheckAvailableTimeslot>>(
      'GET',
      ApiRoutes.apiCheckoutAvailableRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getOptionPrices(
    params: { id: number | string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<Price>>(
      'GET',
      ApiRoutes.apiOptionPricesRoute({id: params.id}).toUrl(),
      params,
      headers
    );
  }

  static async getAvailableDates(
    params: PaginationParams & RansackParams & { option_id: number | string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<AvailableDate>>(
      'GET',
      ApiRoutes.apiAvailableDatesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getDetailedTimeslots(
    params: PaginationParams & RansackParams,
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<DetailedTimeslot>>(
      'GET',
      ApiRoutes.apiDetailedTimeslotsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getCorporateEvent(
    params: { id: string | number } & ViewParams,
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<CorporateEvent>>(
      'GET',
      ApiRoutes.apiCorporateEventRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCollections(
    params?: PaginationParams & {
      view?: string;
      country_id?: string | number;
      city_id?: string | number;
      featured?: number;
      q?: Record<string, any>;
    },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<Collection>>(
      'GET',
      ApiRoutes.apiCollectionsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getCollection(params: { id: string } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Collection>>(
      'GET',
      ApiRoutes.apiCollectionRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCollectionFeaturedGiftOptions(params: { id: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Option>>(
      'GET',
      ApiRoutes.apiCollectionFeaturedGiftOptionsRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCategory(params: { id: string } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Category>>(
      'GET',
      ApiRoutes.apiCategoryRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getSuppliers(params?: PaginationParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Supplier>>(
      'GET',
      ApiRoutes.apiSuppliersRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getTravelItinerary(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<TravelItinerary>>(
      'GET',
      ApiRoutes.apiTravelItineraryRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getTravelItineraries(params?: PaginationParams & RansackParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<TravelItinerary>>(
      'GET',
      ApiRoutes.apiTravelItinerariesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getSupplier(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Supplier>>(
      'GET',
      ApiRoutes.apiSupplierRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getGoogleReviews(
    params?: { q?: Record<string, any> } & PaginationParams,
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<GoogleReview, GoogleReviewInfo>>(
      'GET',
      ApiRoutes.apiCustomerReviewsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getSiteReviews(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<SiteReview>(
      'GET',
      ApiRoutes.apiSiteReviewsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getReviews(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Review>>(
      'GET',
      ApiRoutes.apiReviewsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getCountry(params: { id: number | string } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Country>>(
      'GET',
      ApiRoutes.apiCountryRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCountryPage(params: { id: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<CountryPage>>(
      'GET',
      ApiRoutes.apiPageCountryRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCity(params: { id: number | string } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<City>>(
      'GET',
      ApiRoutes.apiCityRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCityPage(params: { id: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<CityPage>>(
      'GET',
      ApiRoutes.apiPageCityRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getCollectionPage(params: { id: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<CollectionPage>>(
      'GET',
      ApiRoutes.apiPageCollectionRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getActivityPage(params: { id: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<ActivityPage>>(
      'GET',
      ApiRoutes.apiPageActivityRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getBlog(params: { id: string | number } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Blog>>(
      'GET',
      ApiRoutes.apiBlogRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getBlogs(params?: PaginationParams & { [key: string]: any }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Blog>>(
      'GET',
      ApiRoutes.apiBlogsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getBlogFilterItems(params?: PaginationParams & RansackParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<{ kinds: string[] }>>(
      'GET',
      ApiRoutes.apiBlogFilterItemsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getOption(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Option>>(
      'GET',
      ApiRoutes.apiOptionRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getOptions(
    params?: PaginationParams & { q?: Record<string, any> },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<Option>>(
      'GET',
      ApiRoutes.apiOptionsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getOptionPage(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<OptionPage>>(
      'GET',
      ApiRoutes.apiPageOptionRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getHomePage(params?: any, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<HomePage>>(
      'GET',
      ApiRoutes.apiHomePageRoute({}).toUrl(),
      params || {},
      headers
    );
  }

  static async createCorporateInquiry(
    params: {
      name: string;
      email: string;
      quantity?: number;
      topic: string;
      message: string;
    },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<CorporateInquiry>>(
      'POST',
      ApiRoutes.apiCorporateInquiriesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async getTag(params: { id: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Tag>>(
      'GET',
      ApiRoutes.apiTagRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async getTags(params: PaginationParams & RansackParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Tag>>(
      'GET',
      ApiRoutes.apiTagsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async filter(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Option>>(
      'GET',
      ApiRoutes.apiFilterRoute({}).toUrl(),
      params || {},
      headers
    );
  }

  static async createFaq(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<FaqItem>>(
      'POST',
      ApiRoutes.apiFaqsRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async cancelOrder(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<OrderItemCustomerTrip>>(
      'POST',
      ApiRoutes.apiOrderCancelRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async declineOrder(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<OrderItemCustomerTrip>>(
      'POST',
      ApiRoutes.apiOrderDeclineRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async updateOrderContact(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<OrderItemCustomerTrip>>(
      'PATCH',
      ApiRoutes.apiOrderRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async createOrderItemChangeRequest(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{orderItemChangeRequest: OrderItemChangeRequest, invoiceCode: string | null}>>(
      'POST',
      ApiRoutes.apiOrderItemChangeRequestsRoute({}).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async updateFaq(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<FaqItem>>(
      'PATCH',
      ApiRoutes.apiFaqRoute({ id: params.id }).toUrl(),
      omit(params, ['id']) || {},
      headers
    );
  }

  static async deleteFaq(params: { id: string | number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<FaqItem>>(
      'DELETE',
      ApiRoutes.apiFaqRoute({ id: params.id }).toUrl(),
      params || {},
      headers
    );
  }

  static async filterFaq(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<FaqItem>>(
      'GET',
      ApiRoutes.apiFaqFilterRoute({}).toUrl(),
      params || {},
      headers
    );
  }

  static async joinWaitlist(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Waitlist>>(
      'POST',
      AdminRoutes.apiWaitlistsRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getFaqs(
    params: { faqable_id: string | number; faqable_type: string, per_page?: number },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiListResponse<FaqItem>>(
      'GET',
      ApiRoutes.apiFaqsRoute({}).toUrl(),
      params || {},
      headers
    );
  }

  static async addToCart(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Cart>>(
      'POST',
      ApiRoutes.apiCartRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getCart(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Cart>>(
      'GET',
      ApiRoutes.apiCartRoute().toUrl(),
      params,
      headers,
      true
    );
  }

  static async clearCart(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Cart>>(
      'POST',
      ApiRoutes.apiCartClearRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async updateProfile(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<User>>(
      'PATCH',
      ApiRoutes.apiMeRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getGiftCardRedemptions(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<GiftCardRedemption>>(
      'GET',
      ApiRoutes.apiGiftCardRedemptionsRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async redeemGiftCard(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ message: string }>>(
      'POST',
      ApiRoutes.apiGiftCardRedemptionsRedeemRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async userRefundOrder(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Order>>(
      'POST',
      ApiRoutes.apiUserRefundsRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async proceedTrip(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Order>>(
      'POST',
      ApiRoutes.apiConfirmProceedRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async submitRequiredInfo(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Order>>(
      'POST',
      ApiRoutes.apiRequiredInfosRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async rescheduleTrip(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{}>>(
      'PATCH',
      ApiRoutes.apiRescheduleTripRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers,
      true
    );
  }

  static async submitReview(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<Review>>(
      'POST',
      ApiRoutes.apiReviewsRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async search(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<SearchResult>>(
      'GET',
      AdminRoutes.searchRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getSimilarOptions(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<Option>>(
      'GET',
      ApiRoutes.apiSimilarOptionsRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers,
      true
    );
  }

  static async increaseViewCount(
    params: { view_countable_id: string | number; view_countable_type: string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{}>>(
      'POST',
      ApiRoutes.apiViewCountRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async increaseItineraryShareCount(
    params: Record<string, any>,
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{}>>(
      'POST',
      ApiRoutes.apiTravelItineraryIncreaseShareCountRoute({ id: params.id }).toUrl(),
      params,
      headers,
      true
    );
  }

  static async updateCartCurrency(
    params: { cart_id: string | number; currency: Currency; view: string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<Cart>>(
      'PATCH',
      ApiRoutes.apiCartRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async subscribeNewsletter(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ status: string }>>(
      'POST',
      ApiRoutes.apiSubscribesRoute().toUrl(),
      params,
      headers,
      false
    );
  }

  static async sendPasswordResetInstruction(
    params: { user: { email: string } },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{}>>(
      'POST',
      AdminRoutes.passwordRoute().toUrl(),
      params,
      headers,
      true
    );
  }

  static async updatePassword(
    params: {
      user: { password: string; passwordConfirmation: string; resetPasswordToken?: string };
      cartId?: number;
    },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'PATCH',
      AdminRoutes.passwordRoute().toUrl(),
      params,
      headers,
      true
    );
  }

  static async getLastLoginMethod(
    params: { id: string | number },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ user: User }>>(
      'GET',
      ApiRoutes.apiGetLastLoginMethodRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers
    );
  }

  static async sendVerificationCodeToWhatsapp(
    params: { phoneNumber: string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ expiredAt: string }>>(
      'POST',
      ApiRoutes.apiSendVerificationCodeToWhatsappRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async loginWithWhatsapp(
    params: { phoneNumber: string, whatsappCode: string, code?: string, cartId?: number | string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'POST',
      ApiRoutes.apiLoginWithWhatsappRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async loginWithEmail(
    params: { user: { email: string; password: string; }, code?: string, cartId?: number | string },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'POST',
      AdminRoutes.authEmailRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async loginWithGoogle(params: { code?: string; cartId?: number | string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'POST',
      AdminRoutes.authGoogleRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async loginWithFacebook(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'POST',
      AdminRoutes.authFacebookRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async logout(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{}>>(
      'DELETE',
      AdminRoutes.logoutRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async signupAccount(
    params: {
      user: {
        name: string;
        email: string;
        password: string;
        passwordConfirmation: string;
        receiveNewletters: boolean;
      };
      [RECAPTCHA_KEY]: string;
      code?: string;
      cartId?: number | string;
    },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ token: string; user: User }>>(
      'POST',
      AdminRoutes.usersRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async redeemPromotionCode(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<{ currentCart: Cart; redemption: PromotionCodeRedemption; fullPaid: boolean }>
    >('POST', ApiRoutes.apiPromotionCodeRedemptionsRoute({}).toUrl(), params, headers, true);
  }

  static async redeemCorporatePromotionCode(
    params?: Record<string, any>,
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<ApiResponse<{ errors: string[]; redemptions: PromotionCodeRedemption[], numRequiredRedemptions: number }>>(
      'POST',
      ApiRoutes.apiPromotionCodeRedemptionsCorporateRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async removePromotionCode(
    params: { id: number | string; cartId: number; currency: Currency },
    headers?: BasicHttpHeader
  ) {
    return ApiClient.request<
      ApiResponse<{ currentCart: Cart; redemption: PromotionCodeRedemption; fullPaid: boolean }>
    >(
      'DELETE',
      ApiRoutes.apiPromotionCodeRedemptionRoute({ id: params.id }).toUrl(),
      omit(params, ['id']),
      headers,
      true
    );
  }

  static async getCsrfToken(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ token: string }>>(
      'GET',
      ApiRoutes.apiCsrfTokenRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async pay(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<CheckoutPay>>(
      'POST',
      ApiRoutes.apiCheckoutPayRoute({}).toUrl(),
      params,
      headersWithUserToken(headers),
      true
    );
  }

  static async finalizePay(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<any>>(
      'POST',
      ApiRoutes.apiCheckoutFinalizeRoute({}).toUrl(),
      params,
      headersWithUserToken(headers),
      true
    );
  }

  static async payOtp(params?: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<any>>(
      'POST',
      ApiRoutes.apiCheckoutPayOtpRoute({}).toUrl(),
      params,
      headersWithUserToken(headers),
      true
    );
  }

  static async payFail(params: { paymentId: number }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<any>>(
      'POST',
      ApiRoutes.apiCheckoutPayFailRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async sendContactMessage(params: { name: string; email: string; message: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{}>>(
      'POST',
      ApiRoutes.apiContactRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async sendFacebookPixelEvent(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{}>>(
      'POST',
      ApiRoutes.apiSendFacebookPixelEventRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getGiftCode(params: { id: string } & ViewParams, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<GiftCode>>(
      'POST',
      ApiRoutes.apiGiftCodesRoute({}).toUrl(),
      params,
      headers
    );
  }

  static async redeemGiftCode(params: { id: string; } & Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<{ currentCart: Cart; redemption: PromotionCodeRedemption; fullPaid: boolean }>
    >('POST', ApiRoutes.apiRedeemGiftCodeRoute({ id: params.id }).toUrl(), params, headers, true);
  }

  static async removeGiftCode(params: { id: number | string; } & Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<{ currentCart: Cart; redemption: PromotionCodeRedemption; fullPaid: boolean }>
    >('POST', ApiRoutes.apiRemoveGiftCodeRoute({ id: params.id }).toUrl(), params, headers, true);
  }

  static async uploadMapImage(params: { file: string; record: string; }, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<Image>
    >('POST', ApiRoutes.apiMapImageRoute({}).toUrl(), params, headers, true);
  }

  static async countCookieConsent(params: Decamelized<{ targetingCookie: boolean; performanceCookie: boolean; functionalCookie: boolean; }>, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<{}>
    >('POST', ApiRoutes.apiCountCookieConsentRoute({}).toUrl(), params, headers, true);
  }

  static async sendChatMessage(params: { chatConversationId: number; body: string; createdAt?: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<ChatMessage>
    >('POST', ApiRoutes.apiChatMessagesRoute({ id: params.chatConversationId }).toUrl(), omit(params, ['chatConversationId']), headers, true);
  }

  static async readChatMessages(params: { chatConversationId: number; unreadChatMessageIds: number[] }, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<{}>
      >('POST', ApiRoutes.apiReadChatMessagesRoute({ id: params.chatConversationId }).toUrl(), omit(params, ['chatConversationId']), headers, true);
  }

  static async createChatConversation(params: { orderNumber: string; }, headers?: BasicHttpHeader) {
    return ApiClient.request<
      ApiResponse<ChatConversation>
      >('POST', ApiRoutes.apiChatConversationsRoute({}).toUrl(), params, headers, true);
  }

  static async checkUnreadMessage(params?: any, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<{ hasUnreadMessage: boolean }>>(
      'GET',
      ApiRoutes.apiUnreadChatMessagesRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async getPaymentCards(params?: any, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiListResponse<PaymentCard>>(
      'GET',
      ApiRoutes.apiPaymentCardsRoute({}).toUrl(),
      params,
      headers,
      true
    );
  }

  static async updatePaymentCard(params: Record<string, any>, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<PaymentCard>>(
      'PATCH',
      ApiRoutes.apiPaymentCardRoute({ id: params.id }).toUrl(),
      omit(params, ['id']) || {},
      headers,
      true
    );
  }

  static async deletePaymentCard(params: { id: string }, headers?: BasicHttpHeader) {
    return ApiClient.request<ApiResponse<PaymentCard>>(
      'DELETE',
      ApiRoutes.apiPaymentCardRoute({ id: params.id }).toUrl(),
      omit(params, ['id']) || {},
      headers,
      true
    );
  }

  static async request<T>(
    method: HTTP_METHOD,
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, string | undefined>,
    cors?: boolean
  ): Promise<[T | Camelized<T>, Response]> {
    const query = params
      ? qs.stringify(humps.decamelizeKeys(params), {
        arrayFormat: 'brackets',
        addQueryPrefix: !url.includes('?'),
      })
      : '';

    const finalUrl = method === 'GET' ? url + query : url;
    const finalFetch = cors ? corsFetch : fetch;
    // if this function is invoked on server side (Vercel), then this token will be
    // present. so our backend won't check rate limit of request coming from
    // Vercel servers.
    const rateLimitWhitelistToken = process.env.RATE_LIMIT_WHITELIST_TOKEN;
    return finalFetch(finalUrl, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_SS_API_ACCESS_TOKEN}`,
        ...(rateLimitWhitelistToken
          ? { 'X-Rate-Limit-Whitelist-Token': rateLimitWhitelistToken }
          : {}),
        ...(headers || {}),
      },
      credentials: 'include',
      body: method !== 'GET' ? JSON.stringify(humps.decamelizeKeys(params || {})) : null,
    }).then(async (response) => {
      try {
        const json = await response.json();
        return [humps.camelizeKeys<T>(json), response]
      } catch (e) {
        captureException(e, {
          extra: {
            reqUrl: finalUrl,
            reqMethod: method,
            params: params,
          },
        });
        throw e;
      }
    });
  }
}

export default ApiClient;
