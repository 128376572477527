import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
export const checkoutCurrencies = [
  'USD',
  'GBP',
  'AUD',
  'EUR',
  'SGD',
  'MYR',
  'VND',
  'IDR',
  'THB',
  'INR',
  'CNY',
  'JPY',
  'NZD',
  'CHF',
  'CAD'
] as const;
type CheckoutCurrencies = typeof checkoutCurrencies;
export type CheckoutCurrency = CheckoutCurrencies[number];
export type SupplierCurrency = 'TWD' | 'NPR' | 'MYR' | 'VND' | 'INR' | 'LKR' | 'IDR' | 'SGD';
export type Currency = CheckoutCurrency | SupplierCurrency;
export type BookType = 'instant' | 'request_to_book';
export type PriceUnit = 'per_person' | 'per_group' | 'per_room';
export type Nationality = 'foreigner' | 'local';
export type AgeType = 'adult' | 'child' | 'infant';
export type CardNetwork = 'amex' | 'diners' | 'discover' | 'eftpos_au' | 'jcb' | 'mastercard' | 'unionpay' | 'visa';

export type TMoney = {
  amount: number | string;
  currency: Currency;
};

export type ExchangeRate = {
  fromCurrency: Currency;
  toCurrency: Currency;
  rate: number;
};

export type SiteReview = {
  rating: number;
  count: number;
};

export type User = {
  id: number;
  cartId: number | null;
  name: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  supplierId: number;
  receiveNewletters: boolean;
  isAdmin: boolean;
  isPartner: boolean;
  isMember: boolean;
  notificationSettingIds: number[];
  isPartnerAdmin: boolean;
  isPartnerMember: boolean;
  avatarUrl: string | null;
  numReferredPerson: number;
  earnedReferralAmount: TMoney;
  canUseCredit?: boolean;
  masterCreditAmount?: TMoney;
  creditAmount?: TMoney;
  referralCode: string;
  avatar: Image | null;
  phoneNumberVerified: boolean;
  lastLoginMethod: 'google_oauth2' | 'facebook' | 'password' | 'whatsapp';
  shouldCreatePassword: boolean;
  isEmailOwnedByDiscardedUser: boolean;
  supplier: Supplier | null;
};

export enum EnumCollectionKind {
  List = 2,
  Activity = 3,
  Location = 4,
}

export enum PaymentMethod {
  Card = 'card',
  Credit = 'credit',
  GiftCode = 'gift_code',
  PayNow = 'paynow',
  Invoice = 'invoice',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
  GrabPay = 'grabpay'
}

export enum PaymentOrigin {
  Later = 'later',
  Invoice = 'invoice',
  Checkout = 'checkout',
}

export type Image = {
  id: number;
  name: string;
  caption?: string;
  contentType: string;
  url: string;
  xlUrl: string;
  lgUrl: string;
  mdUrl: string;
  smUrl: string;
  webpXlUrl: string;
  webpLgUrl: string;
  webpMdUrl: string;
  webpSmUrl: string;
  blurUrl: string;
  blurBase64: string;
};

export interface ICurrency {
  decimalMark: string;
  fullName: string;
  thousandsSeparator: string;
  exponent: string | number;
  code: Currency;
  symbol: string;
  prefix: string | null;
  suffix: string | null;
  commonGiftAmounts?: number[];
}

export type Country = {
  id: number;
  name: string;
  description: string;
  currency: Currency;
  code: string;
  slug: string;
  image: Image | undefined;
  hideActivitySection: boolean;
  optionsCount: number;
  latitude: number;
  longitude: number;
  featuredCollectionIds?: number[];
  featuredLocationCollectionIds?: number[];
  featuredActivityTagCollectionIds?: number[];
};

export type CountryPage = {
  country: Country;
  countryHighlights: CountryCityHighlight[];
  travelItineraries: TravelItinerary[];
  blogs: {
    data: Blog[];
    pagination: Pagination;
  };
  featuredCollections: Collection[];
  featuredCollectionOptions: FeaturedCollectionOptions;
  cities: City[];
  cityOptions: FeaturedCollectionOptions;
};

export type FeaturedCollectionOptions = Record<
  number | string,
  {
    options: Option[];
    totalCount: number;
    perPage: number;
  }
>;

export type CityPage = {
  travelItineraries: TravelItinerary[];
  blogs: {
    data: Blog[];
    pagination: Pagination;
  };
  faqs: {
    data: FaqItem[];
    pagination: Pagination;
  };
  city: City;
  cityHighlights: CountryCityHighlight[];
  featuredCollections: Collection[];
  featuredCollectionOptions: FeaturedCollectionOptions
};

export type CountryCityHighlight = {
  id: number;
  name: string;
  position: number;
  description: string | null;
  linkableType: 'Collection' | 'Supplier' | 'SupplierActivityOption' | 'City' | null;
  linkableSlug: string | null;
  image: Image;
};

export type City = {
  id: number;
  description: string;
  countryId: number;
  name: string;
  slug: string;
  featured: boolean;
  optionsCount: number;
  updatedAt: string;
  image: Image | undefined;
  hideActivitySection: boolean;
  featuredCollectionIds: number[];
  featuredActivityTagCollectionIds: number[];
  featuredLocationCollectionIds: number[];
  latitude: number;
  longitude: number;
  country?: Country;
  strippedTopThingsToDo?: string;
  topThingsToDo?: string;
};

export type Testimonial = {
  title: string;
  body: string;
  name: string;
  country: string;
  activityName: string;
  imageUrl: string;
};

export type MainEntityItem = { questionName: string; acceptedAnswerText: string };
export type Activity = {
  id: number;
  cityId: number | null;
  slug: string;
  name: string;
  description: string;
  strippedDescription: string;
  leadImage: Image;
  country?: Country;
  optionsCount: number;
  images?: Image[];
  reviewRating?: number;
  ratedReviewsCount?: number;
  minDuration?: number;
  maxDuration?: number;
  distanceToCurrentActivity?: number;
  latitude: number;
  longitude: number;
  nextSeoFaqs: MainEntityItem[];
  updatedAt: string;
  idWithClass: string;
};

export type CollectionTip = {
  id: number;
  kind: string;
  strippedDescription: string;
  description: string;
  image?: Image;
};

export type Collection = {
  id: number;
  kind: string;
  code: string;
  slug: string;
  slugCode: string;
  name: string;
  viewsCount: number;
  createdAt: string;
  description?: string;
  shortDescription?: string;
  strippedDescription?: string;
  optionsCount: number;
  image?: Image;
  images?: Image[];
  giftCountryId?: undefined;
  reviewRating?: number;
  ratedReviewsCount?: number;
  nextSeoFaqs: MainEntityItem[];
  updatedAt: string;
  idWithClass: string;
  countryCode?: string;
  countryName?: string;
  countrySlug?: string;
  countryId?: number;
  citySlug?: string;
  cityName?: string;
  cityId?: number;
  tagId?: number;
  optionLocationId?: number;
  tag?: Tag;
  location?: OptionLocation;
  tips?: CollectionTip[];
  categories?: Category[];
  redirectUrl?: string;
};

export type InitialData = {
  countries: Country[];
  cities: City[];
  currentGeolocation: {
    countryCode: string | null;
  };
  tags: Tag[];
  exchangeRates: ExchangeRate[];
  giftCollections: {
    id: string | number;
    name: string;
    giftCountryId: number;
  }[];
  checkoutSupportedCurrencies: string[];
  settings: Setting;
};

export type SiteBanner = {
  id: number;
  title: string;
  kind: string;
  content: string;
};

export type CountryNav = {
  id: number;
  name: string;
  position: number | undefined;
  navableId: number;
  navableSlug: string;
  navableType: 'ActsAsTaggableOn::Tag' | 'Collection' | 'Activity';
  navableIdType: string;
  navableName: string;
  image: Image | undefined;
};

export type Tag = {
  id: number;
  slug: string;
  name: string;
  parentId: number | null;
  kind: string;
  parent?: Tag | null;
  cityId?: number | null;
  collectionSlug?: string | null;
};

export interface Referral {
  code: string;
}

export type FakePrice = {
  minQtyForPrice: number;
  maxQtyForPrice: number | undefined;
  masterPrice: TMoney;
  price?: TMoney;
  'showDiscount?': boolean;
  crossOutPrice?: {
    masterAmount: TMoney;
    amount?: TMoney;
  };
  discountPercentage?: number;
};

export type OptionHighlight = {
  id: number;
  body: string;
  images: Image[];
};

export type Option = {
  id: number;
  countryId: number;
  cityId: number;
  bookType: BookType;
  baseCurrency: Currency;
  kind: 'stay' | 'short_adventure' | 'flexible_adventure' | 'adventure';
  accommodationSaleType: 'pick_room' | 'entire' | 'assigned_room' | null;
  isInstant: boolean;
  isPublished: boolean;
  isPrivateAccess: boolean;
  privateAccessCode: string | null;
  privateAccessCodeExpiredAt: string | null;
  isDelivery: boolean;
  slug: string;
  slugCode: string;
  name: string;
  covid19Note: string;
  description?: string;
  strippedDescription?: string;
  vimeoIds: string[];
  countryName: string;
  cityName: string;
  supplierName: string;
  supplierEmail: string | null;
  supplierPhoneNum: string | null;
  ratedReviewsCount: number;
  reviewRating: number;
  available?: number | null;
  virtual?: boolean;
  images: Image[];
  createdAt: string;
  updatedAt: string;
  isPrivateTrip?: boolean;
  isGroupTrip?: boolean;
  supplierTimezone: string;
  minDurationNight: number | null;
  minBookingHours: number;
  minQuantity?: number;
  maxQuantity: number;
  maxNumCapacity: number;
  maxBookingDate: string;
  accommodationType: string;
  durationHours?: number;
  durationDays?: number;
  durationNights?: number;
  minCapacity: number;
  maxCapacity: number;
  minAge?: number;
  childAge: number[] | null;
  useChildPrices: boolean;
  priceUnit: PriceUnit;
  minPrice: FakePrice;
  roomDisplayPrice?: FakePrice;
  displayAdvancedGroupPrice?: FakePrice;
  exclusions: string[];
  inclusions: string[];
  highlights?: OptionHighlight[];
  hasReview?: boolean;
  hasFaq?: boolean;
  localDiscountPolicy?: string | null;
  reschedulePolicy?: string | null;
  wetWeatherPolicy?: string | null;
  extenuatingPolicy?: string | null;
  hasLocalPrice: boolean;
  cancelType?: string | null;
  cancellationDueDays: number | null;
  covidPolicyType?: string;
  isGiftable?: boolean;
  requiredCustomerInfos?: RequiredCustomerInfo[];
  requiredCustomerInfoAtCheckout?: boolean;
  packings?: string[];
  topPick: boolean;
  whyTopPick?: string;
  oneLiner?: string;
  featuredTitle?: string;
  featuredDesc?: string;
  mainTag?: Tag;
  locations?: OptionLocation[];
  otherCities?: City[];
  endPoints?: OptionEndPoint[];
  groupPriceType?: string;
  featuredTag?: string;
  subtitle?: string;
  leadIn?: string;
  yachtTrip?: boolean;
  transportItems?: TransportItem[];
  supplierReference?: string;
  badges: { kind: string; description: string }[];
  useSupplierBadges: boolean;
};

export type PromotionCode = {
  id: number;
  title: string;
  code: string;
  isCorporate: boolean;
  isGift: boolean;
  corporateEventId: number | undefined;
  listTnc: string[];
  applicableOptionIds: number[];
  applicableOptions?: Option[];
  discountPercentage?: number;
  discountType: 'fixed' | 'percentage';
  masterAmount: TMoney;
};

export type GiftExperienceCode = {
  id: number;
  code: string;
  quantity: number | null;
  childQuantity: number | null;
  localQuantity: number | null;
  childLocalQuantity: number | null;
  optionId: number | null;
  transportId: number | null;
  addons: OrderItemAddon[];
};

export type TravelItineraryActivity = {
  id: number;
  travelItineraryId: number;
  title: string;
  image: Image;
  dayNumber: number;
  description: string;
  latitude: number;
  longitude: number;
  position: number;
  transportType: 'car' | 'bus' | 'train' | 'air_plane' | 'boat' | 'walking' | null;
  distance: number | null;
  duration: number | null;
  address: string | null;
  option: Option | null;
};

export type TravelItineraryHighlight = {
  id: number;
  travelItineraryId: number;
  body: string;
  image: Image;
  position: number;
};

export type TravelItinerary = {
  id: number;
  slug: string;
  title: string;
  status: 'published' | 'unpublished';
  description: string;
  whyThisWorks: string;
  strippedWhyThisWorks: string;
  cityIds: number[];
  countryId: number | null;
  image: Image;
  vimeoId: number | null;
  updatedAt: string;
  tips: { title: string; description: string; position: number }[];
  activities: TravelItineraryActivity[];
  highlights: TravelItineraryHighlight[];
  cities: City[];
  country: Country;
};

export type Supplier = {
  id: number;
  slug: string;
  name: string;
  email: string | null;
  phoneNum: string | null;
  initial: string;
  experience: number;
  description: string;
  strippedDescription: string;
  currency: Currency;
  commissionRate: number;
  reviewRating: number;
  ratedReviewsCount: number;
  certifications: string[];
  highlights: string[];
  language: string[];
  ologo?: Image;
  optionImages: Image[];
  country: Country;
  reviewFeedbacks?: ReviewFeedback[];
  acceptanceRate: number;
  locations?: string[];
  optionsCount: number;
  badges: { kind: string; description: string }[];
};

export type GoogleReview = {
  id: number;
  name: string;
  reviewDate: string;
  rating: number;
  title: string;
  content: string;
  response: string;
  createdAt: string;
  updatedAt: string;
  strippedContent: string;
};

export type Pagination = {
  currentPage: number;
  totalPages: number;
  prevPage: number | undefined;
  nextPage: number | undefined;
  isFirstPage: boolean;
  isLastPage: boolean;
  totalCount: number;
  limitValue: number;
  offset: number;
};

export type GoogleReviewInfo = {
  averageRating: number;
  rateExcellent: number;
  rateVeryGood: number;
  rateAverage: number;
  ratePoor: number;
  rateTerrible: number;
};

export type CorporateInquiry = {};

export type SitemapLink = { slug: string; updatedAt: string };

export type Sitemap = {
  activities: SitemapLink[];
  options: SitemapLink[];
  blogs: (SitemapLink & { country: string | null; kind: string })[];
  countries: (SitemapLink & { code: string; tags: SitemapLink[] })[];
  cities: (SitemapLink & { tags: SitemapLink[] })[];
  collections: SitemapLink[];
  suppliers: SitemapLink[];
};

export type Blog = {
  id: number;
  title: string;
  excerpt: string;
  slug: string;
  kind: string;
  coverImgUrl?: string;
  actualReadTime?: number;
  metaDescription?: string;
  redirectUrl: string | null;
  updatedAt: string;
  createdAt: string;
  country?: Country;
  city?: City;
  linkable?: {
    id: number;
    name: string;
    slug: string;
    kind: string,
    image: Image;
    optionsCount: number;
  }
  linkableId?: number;
  linkableType?: 'City' | 'Country' | 'Collection';
  faqs?: FaqItem[];
  externalBlog?: ExternalBlog;
  isPublished: boolean;
  optionPrice?: {
    [key: number]: {
      amount: TMoney;
      priceUnit: PriceUnit;
      minQty: number;
      maxQty?: number;
      roomDisplayPrice?: FakePrice;
      displayAdvancedGroupPrice?: FakePrice;
      accommodationSaleType: Option['accommodationSaleType'];
      yachtTrip?: boolean;
      groupPriceType?: Option['groupPriceType'];
      kind: Option['kind'];
    };
  };
  nextSeoFaqs?: MainEntityItem[];
};

export type Setting = {
  teamBuildingCollectionId?: number;
  cityTagline?: string;
  familyWithKidTagId?: number;
  giftGuideNavs?: {
    title: string;
    blogId: string | number;
  }[];
};

export enum EnumAccommodationRoomType {
  double = 'double',
  single = 'single',
  twin = 'twin',
  triple = 'triple',
  family = 'family',
  studio = 'studio',
  suite = 'suite',
  dormBed = 'dorm_bed',
}

export enum EnumPriceUnit {
  perGroup = 'per_group',
  perPerson = 'per_person',
  perRoom = 'per_room',
}

export enum EnumBlogKind {
  quickRead = 'quick_read',
  completeGuide = 'complete_guide',
  itinerary = 'itinerary',
  list = 'list',
  triedAndTested = 'tried_and_tested',
  video = 'video',
}

export enum EnumOptionKind {
  stay = 'stay',
  shortAdventure = 'short_adventure',
  flexibleAdventure = 'flexible_adventure',
  adventure = 'adventure',
}

export enum EnumOptionAccommodationSaleType {
  pickRoom = 'pick_room',
  entire = 'entire',
  assignedRoom = 'assigned_room',
}

export enum EnumOptionCancelType {
  noCancellation = 'all_sales_final',
  before7Days = 'strict',
  before1Day = 'standard',
  before3Days = 'moderate',
}

export enum EnumOptionCovidPolicyType {
  allowRescheduleCancel = 'allow_reschedule_cancel',
  noRescheduleCancel = 'no_reschedule_cancel',
}

export enum EnumTransportItemKind {
  meetup = 'meetup',
  pickup = 'pickup',
  return = 'return',
}

export enum EnumOrderStatus {
  Processing = 'processing',
  Confirmed = 'confirmed',
  Declined = 'declined',
  Completed = 'completed',
  Cancelled = 'cancelled',
  DateTbc = 'date_tbc',
  Awaiting = 'awaiting',
  Rescheduling = 'rescheduling',
  HavingChangeRequest = 'having_change_request'
}

export enum EnumAccommodationGroupTier {
  basic = 'basic',
  upgrade = 'upgrade',
}

export enum EnumTagKind {
  activityType = 'activity_type',
  suitable = 'suitable',
  blogNav = 'blog_nav',
}

export enum EnumAdvancedGroupPriceType {
  pricePerGroupWithAdditionalPaxAddon = 'price_per_group_with_additional_pax_addon',
  pricePerGroupWithMinPax = 'price_per_group_with_min_pax',
}

export type AvailableDate = {
  id: number;
  date: string;
  capacity: number;
  maxNumOfPerson: number;
  isOpen: boolean;
  accommodationRoomId: number | null;
};

export type Timeslot = {
  id: number;
  supplierActivityOptionId: number;
  startTime: string;
  endTime: string;
  kind: string;
  startDate: string;
  weekdays: number[] | null;
  endDate: string | null;
  interval: number | null;
};

export type DetailedTimeslot = {
  id: number;
  startTime: string;
  endTime: string;
  date: string;
  isOpen: boolean;
  capacity: number;
  datetime: string;
};

export type Category = {
  id: number;
  name: string;
  description: string;
  slug: string;
  position?: number;
  image?: Image;
  images: Image[];
};

export type Review = {
  id: number;
  reviewer: string;
  orderItemId: number | null;
  body: string | null;
  strippedBody: string | null;
  reviewDate?: string;
  token: string | null;
  rating: number | null;
  supplierId: number;
  createdAt: string;
  option?: {
    id: number;
    slugCode: string;
    name: string;
    isPublished: boolean;
  };
  reply?: {
    message: string;
    supplierName: string;
    createdAt: string;
  };
  images: Image[];
};

export type CollectionPage = {
  reviews: Review[];
  collection: Collection;
  tagNames: string;
  suppliers: Supplier[];
  showSubPageNav: boolean;
  faqs: {
    data: FaqItem[];
    pagination: Pagination;
  };
  relatedCollections: {
    data: Collection[];
    pagination: Pagination;
  };
  categories: Category[];
  categoryOptions: FeaturedCollectionOptions;
  featuredOptions: Option[];
  blogs: Blog[];
};

export type CategoryOptions = Record<
  number | string,
  { options: Option[]; totalCount: number; perPage: number }
>;

export type ActivityPage = {
  country: Country;
  reviews: Review[];
  activity: Activity;
  categories: Category[];
  categoryOptions: Record<number, { options: Option[]; totalCount: number; perPage: number }>;
};

export type FaqItem = {
  id: string;
  question: string;
  username?: string;
  answer?: string;
  answerHtml?: string;
  formattedAnswer?: string;
  createdAt?: string;
  admin?: string;
  answerTime?: number;
  groupId?: string;
  groupTitle?: string;
};

export type Faqable = {
  id: string;
  type: 'Activity' | 'Blog' | 'SupplierActivityOption' | 'Collection' | 'City';
};

export type ReviewFeedback = {
  id: number;
  count: number;
  name: string;
};

export type Feedback = {
  id: number;
  name: string;
  kind: 'like' | 'improve';
};

export type OptionPage = {
  option: Option;
  collection?: Collection;
  supplier: Supplier;
  availableDates: AvailableDate[];
  availableDatePagination: Pagination;
  enhancedAccommodationGroups: EnhancedAccommodationGroup[];
  accommodationRooms: AccommodationRoom[];
  transportItems: TransportItem[];
  itineraryDays: ItineraryDay[];
  highlights: Highlight[];
  transports: Transport[];
  addons: Addon[];
  tags: Tag[];
  accessibilityTagIds: number[];
  reviewFeedbacks: ReviewFeedback[];
  optionReviewPagination: Pagination;
  faqs: FaqItem[];
  faqPagination: Pagination;
  prices: Price[];
  timeslots: Timeslot[];
  endPoints: OptionEndPoint[];
  giftCollection: {
    slugCode?: string
  }
};

export type AccommodationGroup = {
  id: number;
  name: string;
  tier: string;
};

export type EnhancedAccommodation = {
  night: number;
  type: string;
  parentKind: string;
  roomType: string | null;
  default: boolean;
  numNight: number[];
  numToilet: number;
  name: string;
  cardName: string;
  capacity: number;
  maxCapacity: number;
  roomAmenities: string[];
  accommodationAmenities: string[];
  checkinTime: string | null;
  checkoutTime: string | null;
};

export type EnhancedAccommodationGroup = {
  id: number;
  tier: string;
  nights: number[];
  allNights: boolean;
  capacityOfNights: Record<number, [number, number]>;
  scrollCardUi: boolean;
  name: string;
  nameInfo: string;
  bedInfo: Record<number, string>;
  capacityInfo: Record<
    number,
    { text: string | null; plainText: string | null; aryCap: [number | null, number | null] }
  >;
  toiletInfo: Record<number, string | null>;
  underscoredName: string;
  lightboxImages: {
    id: number;
    jsid: string;
    caption: string | null;
    title: string;
    desc: string;
    url: string | null;
  }[];
  roomCards: {
    jsid: string;
    imageId: number;
    imageUrl: string;
    imageCount: number;
    line1: string;
    line2: string;
    line3: string;
  }[];
  parentAccommodation: {
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    checkinTime: string | null;
    checkoutTime: string | null;
  };
  accommodations: EnhancedAccommodation[];
};

export type ItineraryDay = {
  id: number;
  title: string;
  summary: string;
  descriptionLines: string[];
  image: Image | null;
  images: Image[];
};

export type Waitlist = {
  id: number;
};

export type OrderItem = {
  id: number;
  status: string;
  friendlyStatus: string;
  supplierActivityOptionId: number | null;
  giftCardId: number | null;
  giftImageId: number | null;
  detailedTimeslotId: number | null;
  startTime: string | null;
  endTime: string | null;
  requestExpiredAt: string | null;
  accommodationGroupId: number | null;
  transportId: number | null;
  startDate: string;
  endDate: string | null;
  isInstant: boolean;
  quantity: number;
  childQuantity: number;
  localQuantity: number;
  canManageAffectedByCovid: boolean;
  childLocalQuantity: number;
  totalQuantity: number;
  totalAdultQty: number;
  totalChildQty: number;
  hasLocal: boolean;
  canCancel: boolean;
  cancelableDate: string | null;
  numNights: number;
  isGift: boolean;
  isGiftExperience: boolean;
  isGiftRedemption: boolean;
  masterAmount: TMoney;
  amount: TMoney;
  totalAmount: TMoney;
  currency: Currency;
  childAmount: TMoney;
  localDiscountMasterAmount: TMoney;
  localDiscountAmount: TMoney;
  transportAmount: TMoney;
  giftImage?: Image | null;
  transport?: Transport;
  accommodationGroup?: EnhancedAccommodationGroup;
  orderItemAddons?: OrderItemAddon[];
  orderItemAccommodationRooms?: OrderItemAccommodationRoom[];
  orderItemGiftRecipients?: OrderItemGiftRecipients[];
  promotionCodeRedemptions?: PromotionCodeRedemption[];
  bookType: BookType;
  checkStatusHours: number;
  requiredCustomerInfoDone: boolean;
  allRequiredCustomerInfoDone: boolean;
  isAffectedByCovid: boolean;
  isRescheduleLater: boolean;
  isAwaiting: boolean;
  isRescheduling: boolean;
  isProceeded: boolean;
  rescheduleStartDate: string | null;
  rescheduleEndDate: string | null;
  rescheduleStartTime: string | null;
  rescheduleEndTime: string | null;
  rescheduleDetailedTimeslotId: number | null;
  accommodationName: string | null;
  deliveryType: string | null;
  giftCard?: GiftCard;
  sendGiftDate: string | null;
  recipientMsg: string | null;
  giftCardSender: string | null;
  paidAddons?: OrderItemAddon[];
  displayTotalPayoutAmount: TMoney;
};

export type OrderItemWithOption = OrderItem & {
  option?: Option;
};

export type OrderItemWithOrder = OrderItem & {
  order: Order;
};

export type OrderItemWithOptionOrder = OrderItem & {
  option?: Option;
  order: Order;
  review: Review | null;
};

type OrderWithPayments = Order & {
  payments: Payment[];
  billingDetails: { [k: string]: any } | null;
};

export type OrderItemCustomerTrip = OrderItem & {
  detailedTimeslot: DetailedTimeslot;
  rescheduleDetailedTimeslot: DetailedTimeslot;
  order: OrderWithPayments;
  option: Option & {
    itineraryDays: ItineraryDay[];
    transportItems: TransportItem[];
    hasSeasonalPrice: boolean
  };
  checkoutPayment: Payment | null;
  orderItemChangeRequestPayment?: Payment;
  numGuestCanAdd: number;
  canChangeDate: boolean;
  review: Review;
  accommodationGroup?: EnhancedAccommodationGroup;
  customerActions: CustomerAction[];
  customers: CustomerInfo[];
  paidAddons: OrderItemAddon[];
  selectedTransportItem: TransportItem;
  selectedTransportItemDetail: string | null
  orderItemChangeRequest: OrderItemChangeRequest | null;
  checkoutLineItemAmount: TMoney;
};

export type TransportTime = {
  id: number;
  startTime: string;
  endTime: string;
};

export type TransportItem = {
  id: number;
  kind: string;
  startPointAddress: string;
  startPointDescription: string;
  startPointLatitude: number;
  startPointLongitude: number;
  startPointTime?: string;
  note?: string;
  timeFlexible: boolean;
  transportTimes: TransportTime[];
  mapImageCacheKey: string | null;
  freshMapImageCacheKey: string | null;
  mapImage: Image | null;
};

export type OptionEndPoint = {
  id: number;
  supplierActivityOptionId: number;
  address: string;
  description: string;
  latitude?: number;
  longitude?: number;
};

export type Highlight = {
  id: number;
  name: string;
  description: string;
  image: Image;
};

export type Transport = {
  id: number;
  name: string;
  masterAmount: TMoney;
  amount: TMoney;
  description: string;
  capacity?: number;
};

export type Addon = {
  id: number;
  name: string;
  description: string | null;
  maxQty: number | null;
  masterAmount: TMoney;
  amount: TMoney;
};

export type OrderItemAddon = {
  id?: number;
  name: string;
  qty: number;
  addonId: number;
  amount: TMoney;
  masterAmount: TMoney;
};

export interface OrderItemChangeRequest {
  id: number;
  quantity: number;
  localQuantity: number;
  childQuantity: number;
  childLocalQuantity: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  kind: 'change_quantity' | 'change_date';
  status: 'inactive' | 'pending' | 'accepted' | 'declined';
  topupAmount: TMoney;
  checkoutLineItemAmount: TMoney;
  previousOrderItemData: {
    endDate: string;
    endTime: string | null;
    quantity: number;
    startDate: string;
    startTime: string | null;
    childQuantity: number;
    localQuantity: number;
    childLocalQuantity: number;
  };
}

export type CrossOutPrice = {
  id: number;
  priceId: number;
  amount: TMoney;
  masterAmount: TMoney;
};

export type Price = {
  id: number;
  supplierActivityOptionId: number;
  minQtyForPrice: number;
  maxQtyForPrice: number | null;
  price: TMoney;
  createdAt: string;
  updatedAt: string;
  agentPrice: TMoney;
  accommodationGroupId: number | null;
  accommodationRoomId: number | null;
  masterPrice: TMoney;
  masterPriceCents: number;
  masterPriceCurrency: Currency;
  ageType: AgeType;
  nationality: Nationality;
  tripStartDate: string | null;
  tripEndDate: string | null;
  discounted: boolean;
  weekdays: number[] | null;
  priceGroupId: number;
  excludedDates: string[];
  localId: number | null;
  localPrice: TMoney | null;
  localAgentPrice: TMoney | null;
  crossOutPrice: CrossOutPrice | null;
  localCrossOutPrice: CrossOutPrice | null;
  priceGroupKind: string | null;
  hasDiscountSmallGroups: boolean | false;
};

export type OptionPriceCalculation = {
  amount: TMoney;
  childAmount: TMoney;
  localDiscountAmount: TMoney;
  transportAmount: TMoney;
  totalPrice: TMoney;
  totalCrossOutPrice: TMoney;
  totalAddonAmount: TMoney;
};

export type GiftCard = {
  id: number;
  name: string;
  amount: TMoney;
  masterAmount: TMoney;
  creditAmount: TMoney;
  countryId: number;
};

export type Cart = {
  id: number;
  orderItemId: number | null;
  numPossibleRedemptions: number;
  useCredit: boolean;
  deductedCreditAmount: TMoney;
  totalAmount: TMoney;
  exchangedTotalAmountWithConversionFee: TMoney;
  exchangedDeductedCreditAmount: TMoney;
  exchangedSubtotalAmount: TMoney;
  exchangedSubtotalAmountWithConversionFee: TMoney;
  exchangedReferralAmount: TMoney;
  exchangedPromoCodeDiscountAmount: TMoney;
  totalCrossOutPrice: TMoney;
  universalPromoCodeDiscountAmount: TMoney;
  universalExchangedPromoCodeDiscountAmount: TMoney;
  subtotalAmount: TMoney;
  promotionCode: string | null;
  referralAmount: TMoney;
  orderItem: OrderItem | null;
  fullPaymentByCredit: boolean;
  giftCode: string | null;
  giftCodeAmount: TMoney;
  exchangedGiftCodeAmount: TMoney;
};

export type RequiredCustomerInfo = {
  id: number;
  keyName: string;
  label: string;
  placeholder: string;
  inputType: string;
  optional: boolean;
  dataType: string;
  validationRule: string | null;
  possibleValues: string[] | null;
  position: number | null;
  isSubmitted: boolean;
};

export type CustomerInfo = Record<string, any>;

export type RedemptionResult = {
  currentCart: Cart;
  redemption: { id: number; code: string; } | null;
  fullPaid: boolean;
};

export type CorporateRedemptionStatus = {
  kind: 'success' | 'error' | 'requires_enter_code';
  message: string[] | string;
};

export type PromotionCodeRedemption = {
  id: number;
  code: string;
  promotionCode: GiftExperienceCode;
};

export type CheckoutFormikValues = {
  cartId?: number | null;
  orderItemId?: number;
  customerName?: string;
  customerEmail?: string;
  contactNum?: string;
  customerInfo?: string;
  currency: Currency;
  paymentMethod: PaymentMethod;
  paymentOrigin: PaymentOrigin;
  paymentMethodId?: string;
  saveCard?: boolean;
  invoiceId?: number;
  paymentId?: number;
  receiveNewletters?: boolean;
  customers?: CustomerInfo[];
  recipientName?: string;
  recipientEmail?: string;
  recipientMsg?: string;
  sendGiftDate?: string | null;
  useCreditAccount?: string | null;
  deliveryType?: 'single' | 'multi' | null;
  totalAmount?: string | number;
  creditAmount?: string | number;
  giftCodeAmount?: string | number;
  orderReference?: string | null;
  paymentDescription: string;
  bookType?: BookType;
  billingDetails?: StripeAddressElementChangeEvent['value'];
};

export interface Order {
  number: string;
  customerName: string;
  customerEmail: string;
  contactNum: string;
  customerInfo: string | null;
  totalAmount: TMoney;
  totalPaidAmount: TMoney;
  currency: Currency;
  creditAmount: TMoney;
  conversionFeeAmount: TMoney;
  reference: string;
  totalRefundableAmount: TMoney;
  initialPaidAt: string | null;
  displaySubtotalAmount: TMoney;
  totalSubtotalAmount: TMoney;
  referralAmount: TMoney;
  stripeRefundedAmount: TMoney;
  specialDiscountAmount: TMoney;
  totalNetAmount: TMoney;
  promoCodeDiscountAmount: TMoney;
  creditRefundedAmount: TMoney;
  giftCodeRefundedAmount: TMoney;
  capturedAmount: TMoney;
  refundedAmount: TMoney;
  totalPaidAmountWithoutDiscount: TMoney
  hasPendingRefund: boolean;
  refundProcessDate: string | null;
  giftCodeAmount: TMoney;
  giftCode?: string;
  createdAt: string;
}

export type OrderWithItem = {
  orderItem: OrderItem;
} & Order;

export type OrderLaterPayment = {
  payment: Payment;
} & OrderWithItem;

export interface InvoiceItem {
  id: number;
  name: string;
  amount: TMoney;
  createdAt: string;
  updatedAt: string;
}

export interface Invoice {
  id: number;
  code: string;
  totalAmount: TMoney;
  conversionFeeAmount: TMoney;
  stripeAmount: TMoney;
  creditAmount: TMoney;
  description: string;
  paidAt: string;
  expiredAt: string;
  createdAt: string;
  isPaid: boolean;
  isExpired: boolean;
  items: InvoiceItem[];
  order: OrderWithItem;
  kind: 'normal' | 'reschedule' | 'change_date' | 'change_quantity'
  orderItemChangeRequest?: OrderItemChangeRequest
}

export type InvoiceWithOrder = {
  order: Order;
} & Invoice;

export type SetupIntentResponse = {
  error?: string;
  requiresAction?: boolean;
  paymentId?: number;
  paymentIntentId?: string;
  paymentIntentClientSecret?: string;
};

export type PaymentTransaction = {
  id: number;
  transactionType: string;
  status: string;
  amount: TMoney;
  createdAt: string;
  updatedAt: string;
};

export type Payment = {
  id: number;
  paymentMethodId: string;
  laterPayExpiredAt?: string;
  isLaterPayExpired: boolean;
  intentClientSecret?: string;
  stripeDeclineCode?: string;
  last4Digits?: string;
  paymentMethod: string;
  isCheckout: boolean;
  invoice?: Invoice;
  orderItemId?: number;
  status: string;
  amount: TMoney;
  conversionFeeAmount: TMoney;
  updatedAt: string;
  paymentTransactions: PaymentTransaction[];
  orderParams: { [key: string]: any } | null;
};

export type TotalPriceCalculation = {
  exchangedDeductedCreditAmount: TMoney;
  exchangedTotalAmountWithConversionFee: TMoney;
};

export type ApiError = {
  error: string;
};

export type CheckAvailableTimeslot = {
  available: boolean;
  shouldRefresh: boolean;
};

export type ExternalBlogMedia = {
  title?: string;
  bodyHtml?: string;
  sectionTitle?: string;
  alignType: 'left' | 'right';
  optionEmbed?: { provider: string; html: string };
  image?: {
    url: string;
    alt?: string;
  };
  link?: string;
};

export type BlogType = 'alist' | 'list' | 'article';
export type ExternalBlog = {
  afterword?: string;
  type: BlogType;
  tableContent?: string;
  media?: ExternalBlogMedia[];
  bodyHtml?: string;
  bodyAtTheEndHtml?: string;
};

export type AccountTransaction = {
  id: number;
  amount: TMoney;
  giftCardCode: string | null;
  orderNumber: string | null;
  sourceType: string;
  updatedAt: string;
  kind: string;
};

export type GiftCardRedemption = {
  id: number;
  creditCurrency: string;
  code: string;
};

export type CustomerAction = {
  id: string;
  shortName: string;
  title: string;
  tripDesc: string;
  url: string;
};

export type AccommodationRoomBed = {
  id: number;
  name: string;
  qty: number;
};

export type AccommodationRoomAmenity = {
  id: number;
  name: string;
};

export type AccommodationRoom = {
  id: number;
  name: string;
  kind: 'bedroom' | 'shared_space';
  roomType: string;
  minAmount: TMoney;
  images: Image[];
  beds: AccommodationRoomBed[];
  amenities: AccommodationRoomAmenity[];
  maxNumOfPerson: number;
  qty: number;
  numPrivateBathroom: number | null;
  numSharedBathroom: number | null;
  ordinalNight: number | null;
};

export type OrderItemAccommodationRoom = {
  id?: number;
  accommodationRoomName: string;
  accommodationRoomId: number;
  qty: number;
  roomQty: number;
  accommodationRoom?: AccommodationRoom;
  numOfPerson: number;
  maxNumOfPerson: number;
  amount: TMoney;
  masterAmount: TMoney;
};

export type OrderItemGiftRecipients = {
  id?: number | null;
  name: string;
  email: string;
};

export type BookingFormValues = {
  supplierActivityOptionId: number;
  quantity: number;
  childQuantity: number;
  localQuantity: number;
  childLocalQuantity: number;
  hasLocal: boolean;
  detailedTimeslotId: number | null;
  addons: OrderItemAddon[];
  orderItemAccommodationRooms: OrderItemAccommodationRoom[];
  transportId: number | null;
  currency: Currency;
  startDate: string | null;
  endDate: string | null;
  giftExperience: boolean | undefined;
  bookType: BookType;
  giftType: 'experience' | 'redemption' | null;
};

export type SearchResult = {
  countriesCities: {
    id: number;
    name: string;
    slug: string;
    type: 'Country' | 'City';
  }[];
  results: {
    type: string;
    id: number;
    slugCode: string;
    name: string;
    imageUrl: string;
    cityName: string;
    code: string;
  }[];
};

export type CorporateEvent = {
  id: number;
  name: string;
  slug: string;
  isOngoing: boolean;
  isUnfinished: boolean;
  startDate: string;
  endDate: string;
  endTime: string;
  clientName: string;
  promoCodePrefix: string;
  redeemLimit: number;
  page: {
    title: string;
    featureTitle: string | null;
    description: string;
    navName: string | null;
    aboutNavName: string | null;
    featureNavName: string | null;
    categoryNavName: string | null;
    hasCategories: boolean;
    bannerImage: Image;
    corporateLogoImage: Image;
    showCountdownTimer: boolean;
    aboutSections: {
      title: string;
      descripition: string;
      image: Image;
      viewMoreType: string | null;
      viewMoreVimeoUrl: string | null;
      viewMoreImage: Image;
      viewMoreDesc: string | null;
    }[];
    featureSections: {
      title: string;
      description: string;
      image: Image;
      vimeoUrl: string | null;
      viewMoreUrl: string | null;
    }[];
  };
};

export type FilterTab = 'option' | 'collection' | 'blog';
export type FilterableType =
  | 'Country'
  | 'City'
  | 'Collection'
  | 'ActsAsTaggableOn::Tag'
  | 'Category'
  | 'Activity'
  | 'CorporateEvent'
  | 'Fts'
  | 'Supplier';

export type FilterRecord = {
  id: number | string;
  type:
    | 'City'
    | 'Country'
    | 'ActsAsTaggableOn::Tag'
    | 'Category'
    | 'Activity'
    | 'Collection'
    | 'Fts';
  name: string;
  parentType?: 'Activity' | 'Collection' | 'City' | 'Country';
  parentId?: number;
  slug?: string;
  slugCode?: string;
};

export type OptionLocation = {
  id: number;
  name: string;
  nameWithCity: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  collectionSlugCode?: string;
};

export type FilterOption = {
  label: string;
  value: string | number;
  desc?: string;
  children?: FilterOption[];
};

export type FilterParams = {
  tab?: FilterTab;
  kind?: string[];
  sortBy?: string;
  activityType?: string[];
  time?: string[];
  locationIds?: string[];
  cityIds?: string[];
  suitableFor?: string[];
  prices?: (number | string | undefined)[];
  childAge?: string[];
  durations?: string[];
  tripType?: string;
  cityId?: number;
  countryId?: number;
  specificId?: number;
};

export type HomePage = {
  options: Option[];
};

export type CityCollection = {
  id: number;
  type: 'City' | 'Collection' | 'Country';
  slug: string;
  slugCode: string;
  name: string;
  image: Image | null;
};

export type HeroItem = {
  id: string;
  image: Image | null;
  vimeoId: string | null;
  description: string | null;
  url: string | null;
};

export type ScrollableRecord = {
  id: number;
  name: string;
  slug: string;
  type?: string;
  giftCountryId?: number;
};

export type SimpleCollection = {
  id: number;
  url: string;
  name: string;
  strippedDescription: string;
  image?: Image;
};

export type CheckoutPay = {
  requiresAction?: boolean;
  paymentId?: number;
  paymentIntentId?: string;
  paymentIntentClientSecret?: string;
  payment?: Record<string, any>;
  paymentMethodId?: string;
  redirectUrl?: string;
  nextActionType?: 'redirect_to_url' | 'use_stripe_sdk' | 'alipay_handle_redirect' | 'oxxo_display_details' | 'verify_with_microdeposits';
};

export type GiftCodeTransaction = {
  id: number;
  orderNumber?: string;
  action: 'topup' | 'refund' | 'redeem';
  kind: 'credit' | 'debit';
  amount: TMoney;
  createdAt: string;
};

export type GiftCode = {
  id: number;
  code: string;
  amount: TMoney;
  balanceAmount: TMoney;
  status: 'credited' | 'redeemed' | 'active' | 'expired' | 'cancelled';
  kind: 'gift_card' | 'gift_experience';
  title: string;
  optionDetails: {
    quantity?: number;
    childQuantity?: number;
    localQuantity?: number;
    childLocalQuantity?: number;
    transportId?: number;
    addons?: {
      addonId: number;
      qty: number;
    }[]
  };
  startDate: string;
  endDate: string;
  image: Image;
  supplierActivityOptionId?: number;
  senderName: string;
  giftCodeTransactions: GiftCodeTransaction[];
  giftableType: 'SupplierActivityOption' | 'GiftCard';
  giftableId: number;
  newRecord?: boolean;
}

export type ChatConversation = {
  id: number;
  title: string;
  userId: number | null;
  imageUrl: string | null;
  latestMessage: {
    id: number;
    senderId: number | null;
    senderType: 'User' | 'Supplier' | null;
    body: string;
    strippedBody: string;
    createdAt: string;
  }
  hasUnreadMessage: boolean;
  orderItems: OrderItemWithOptionOrder[];
  createdAt: string;
}

export type ChatMessage = {
  id: number;
  chatConversationId: number;
  nanoid?: string;
  body: string;
  strippedBody: string;
  senderName: string;
  senderAvatarUrl: string | null;
  senderType: 'Supplier' | 'User' | null;
  senderId: number | null;
  createdAt: string;
  isSystemNoti: boolean;
  isUnread: boolean;
  isFailed?: boolean;
}

export type PaymentCard = {
  paymentMethodId: string;
  cardNetwork: CardNetwork;
  last4Digits: string;
  default: boolean;
  billingDetails: StripeAddressElementChangeEvent['value'];
  expirationDate: string;
}
